import React from 'react';
import PropTypes from 'prop-types';

// import DownloadXl from '../../../../svg/pdf-xl.svg';
// import DownloadSm from '../../../../svg/pdf-sm.svg';

const ContentCenter = ({ title, description }) => {
  return (
    <>
      <div className="content-center">
        <div className="content-center__info">
          <span className="main-decription">{description}</span>
          <h1 className="h1">{title}</h1>
        </div>
        {/* <a href={`https://admin.zerotabletop.com${pdf?.url}`} className="download-pdf" target="blank">
          <DownloadXl className="download-pdf__icon-xl" />
          <DownloadSm className="download-pdf__icon-sm" />
          <span className="download-pdf__title">
            {'Download pdf \nportfolio'}
          </span>
        </a> */}
      </div>
    </>
  );
};

ContentCenter.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  // pdf: PropTypes.string,
};

export default ContentCenter;
