export const photographerInnerStatic = {
  main_decription: 'Photographer:',
  crumbsNav: [
    { id: 1, title: 'Home', link: '/' },

  ],
  tickers: [
    'Portfolio',
    'Portfolio',
    'Portfolio',
    'Portfolio',
    'Portfolio',
    'Portfolio',
    'Portfolio',
    'Portfolio',
  ],
  bio_marker: 'Photographer',
};
