import React from 'react';
// import { LazyLoadImage } from "react-lazy-load-image-component";

import PropTypes from 'prop-types';

const LightBox = ({
  image,
  closeModal,
  goBack,
  goForward,
  selectedImage,
  photos,
  showLightbox,
}) => {
  return (
    <div className={showLightbox ? 'lightbox is-show' : 'lightbox'}>
      <div className="lightbox-overlay" onClick={closeModal} role="presentation"></div>
      <div className="lightbox-wrapper">
        <div className="lightbox-image__wrapper__photographer">
          <img
            src={`https://admin.zerotabletop.com${image}`}
            className="lightBox-image__photographer"
            alt="zerotabletop"
          />
        </div>

        <button className="light-box-close" onClick={closeModal}>
          Close
        </button>
        <button
          className="light-box__arrow light-box__prev"
          onClick={goBack}
          disabled={selectedImage === 0}
        >
          Prev
        </button>
        <button
          className="light-box__arrow light-box__next"
          onClick={goForward}
          disabled={selectedImage === photos.length - 1}
        >
          Next
        </button>
      </div>
    </div>
  );
};

LightBox.propTypes = {
  image: PropTypes.string,
  closeModal: PropTypes.func,
  goBack: PropTypes.func,
  goForward: PropTypes.func,
  selectedImage: PropTypes.number,
  photos: PropTypes.array,
  showLightbox: PropTypes.bool,
};

export default LightBox;
