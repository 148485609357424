import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/layout';
import Seo from '../components/seo';

import { photographerInnerData } from '../db/photographerInnerData';
import { photographerInnerStatic } from '../db/photographerInnerStatic';
import {
  MainScreenThird,
  Biography,
  CrumbsNav,
  PhotographerPortfolio,
  // DownloadPdf,
} from '../components/Pages/PhotographerInner';

const photographerInnerPage = ({data}) => {

  const beauty = data.strapiPhotographersInnerPage
  const food = data.strapiFoodPhotographers

  return (
    <Layout>
      <Seo 
        title={beauty ? beauty.seo_title : food.seo_title} 
        description={beauty ? beauty.seo_description : food.seo_description} 
      />

      <MainScreenThird
        title={beauty ? beauty.Main.title : food.main.title}
        description={photographerInnerData.main_decription}
        showreel={beauty ? beauty.Main.showreel : food.main.showreel}
        // pdf={beauty ? beauty.Main.pdf : food.main.pdf}
        poster={beauty ? beauty.Main.poster : food.main.poster}
      />

      <PhotographerPortfolio
        portfolioData={photographerInnerData.portfolio}
        tickerData={photographerInnerStatic.tickers}
        dataPortfolio={beauty ? beauty.portfolio : food.portfolio}
      />

      <Biography
        biographyData={beauty ? beauty : food}
        marker={photographerInnerStatic.bio_marker}
      />

      {/* <DownloadPdf pdf={beauty ? beauty.Main.pdf : food.main.pdf} /> */}

      <CrumbsNav 
        dataCrumbs={photographerInnerStatic.crumbsNav} 
        link={beauty ? '/beauty-photographers/' : "/food-photographers/"}
        firstTitle={beauty ? 'Beauty Photographers' : 'Food Photographers'}
        title={beauty ? beauty.Main.title : food.main.title}
        slug={beauty ? "/beauty-photographers/" + beauty.slug : "/food-photographers/" + food.slug}
        />
    </Layout>
  );
};

export default photographerInnerPage;

export const query = graphql`
  query PhotographerInnerPage($id: String!)  {
    strapiPhotographersInnerPage(id: { eq: $id }) {
      seo_title
      seo_description
        Main {
        title
        showreel {
          url
        }
        pdf {
        url
        }
        poster {
          url
        }
      }
      portfolio
      title
      slug
      biography
      photo {
        localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1400
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    strapiFoodPhotographers(id: { eq: $id }) {
      seo_title
      seo_description
      main {
        title
        showreel {
          url
        }
        pdf {
        url
        }
        poster {
          url
        }
      }
      portfolio
      title
      biography
      slug
      photo {
        localFile {
          childImageSharp {
            gatsbyImageData(
                width: 1400
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
