import React from 'react';
// import PropTypes from 'prop-types';

import Ticker from '../../../UiComponents/Ticker/Ticker';
import ThreePortraitsBottom from './ThreePortraitsBottom/ThreePortraitsBottom';
import ThreePortraitsTop from './ThreePortraitsTop/ThreePortraitsTop';
import ThreeAsymmetricalPortraits from './ThreeAsymmetricalPortraits/ThreeAsymmetricalPortraits';
import TwoPortraits from './TwoPortraits/TwoPortraits';
import FourPortraits from './FourPortraits/FourPortraits';
import LandscapePortrait from './LandscapePortrait/LandscapePortrait';
import PortraitLandscape from './PortraitLandscape/PortraitLandscape';
import TwoLandscapes from './TwoLandscapes/TwoLandscapes';
import OneLandscape from './OneLandscape/OneLandscape';

const PhotographerPortfolio = ({
  tickerData,
  dataPortfolio,
}) => {

  return (
    <>
      <div
        id="second-section"
        className="photographer-portfolio__inner-header second-section"
        data-scroll-section
      >
        <Ticker tickers={tickerData} />
      </div>
      <div className="photographer-portfolio__inner">
        <div className="content-wrapper">
          <div className="photographer-portfolio__inner-items">
            {dataPortfolio.map((item, index) => {
              if (item.strapi_component === 'two-portraits.two-portraits') {
                return <TwoPortraits key={index} photos={item.photos} />;
              } else if (
                item.strapi_component ===
                'three-portraits-bottom.three-portraits-bottom'
              ) {
                return (
                  <ThreePortraitsBottom key={index} photos={item.photos} />
                );
              } else if (
                item.strapi_component ===
                'three-portraits-top.three-portraits-top'
              ) {
                return <ThreePortraitsTop key={index} photos={item.photos} />;
              } else if (
                item.strapi_component ===
                'three-asymmetrical-portraits.three-asymmetrical-portraits'
              ) {
                return (
                  <ThreeAsymmetricalPortraits
                    key={index}
                    photos={item.photos}
                  />
                );
              } else if (
                item.strapi_component === 'four-portraits.four-portraits'
              ) {
                return <FourPortraits key={index} photos={item.photos} />;
              } else if (
                item.strapi_component ===
                'landscape-portrait.landscape-portrait'
              ) {
                return <LandscapePortrait key={index} photos={item.photos} />;
              } else if (
                item.strapi_component ===
                'portrait-landscape.portrait-landscape'
              ) {
                return <PortraitLandscape key={index} photos={item.photos} />;
              } else if (
                item.strapi_component === 'two-landscapes.two-landscapes'
              ) {
                return <TwoLandscapes key={index} photos={item.photos} />;
              } else if (
                item.strapi_component === 'one-landscape.one-landscape'
              ) {
                return <OneLandscape key={index} photos={item.photos} />;
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

// PhotographerPortfolio.propTypes = {};

export default PhotographerPortfolio;
