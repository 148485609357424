import React from 'react';
import PropTypes from 'prop-types';
import ContentCenter from './ContentCenter/ContentCenter';
import BottomLine from './BottomLine/BottomLine';

const MainScreenThird = ({ title, description, showreel, pdf, poster }) => {
  return (
    <section className="main-screen" id="top-section" data-scroll-section>
      <div className="main-screen__background">
        <video
          preload="auto"
          poster={`https://admin.zerotabletop.com${poster?.url}` ? `https://admin.zerotabletop.com${poster?.url}` : null}
          className="main-screen__background-video"
          src={`https://admin.zerotabletop.com${showreel?.url}`}
          playsInline={true}
          webkit-playsinline=""
          autoPlay={true}
          muted={true}
          loop={true}
        ></video>
      </div>
      <div className="page-wrapper">
        <ContentCenter title={title} description={description} pdf={pdf} />
        <BottomLine />
      </div>
    </section>
  );
};

MainScreenThird.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  // pdf: PropTypes.string,
};

export default MainScreenThird;
