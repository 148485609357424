import React from 'react';

import LightBox from '../../../../UiComponents/LightBox/LightBox';
import PropTypes from 'prop-types';

const ThreeAsymmetricalPortraits = ({ photos }) => {
  const [showLightbox, setShowLightbox] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(0);

  function handleClick(e, index) {
    e.preventDefault();
    setShowLightbox(!showLightbox);
    setSelectedImage(index);
  }

  function closeModal() {
    setShowLightbox(false);
    setSelectedImage(0);
  }

  function goBack() {
    setSelectedImage(selectedImage - 1);
  }

  function goForward() {
    setSelectedImage(selectedImage + 1);
  }

  React.useEffect(() => {
    window.addEventListener('keyup', handleKeyUp, false);

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
    };
  });

  function handleKeyUp(e) {
    e.preventDefault();
    const { keyCode } = e;
    if (showLightbox) {
      if (keyCode === 37) {
        // Left Arrow Key
        if (selectedImage > 0) {
          setSelectedImage(selectedImage - 1);
        }
      }
      if (keyCode === 39) {
        // Right Arrow Key
        if (selectedImage < photos.length - 1) {
          setSelectedImage(selectedImage + 1);
        }
      }
      if (keyCode === 27) {
        // Escape key
        setShowLightbox(false);
      }
    }
  }
  return (
    <>
      <section className="ThreeAsymmetricalPortraits" data-scroll-section>
        {photos.map((item, index) => {
          return (
            <div className="ThreeAsymmetricalPortraits-item" key={index}>
              <div className="ThreeAsymmetricalPortraits-item__wrapper-oweflow">
                <div
                  className="ThreeAsymmetricalPortraits-item__wrapper"
                  onClick={(e) => handleClick(e, index)}
                  role="presentation"
                >
                  <img
                    src={`https://admin.zerotabletop.com${item?.url}`}
                    className="ThreeAsymmetricalPortraits-item__photo"
                    loading="lazy"
                    alt="zerotabletop"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </section>
      {showLightbox && (
        <LightBox
          image={photos[selectedImage].url}
          closeModal={closeModal}
          goBack={goBack}
          goForward={goForward}
          selectedImage={selectedImage}
          photos={photos}
          showLightbox={showLightbox}
        />
      )}
    </>
  );
};

ThreeAsymmetricalPortraits.propTypes = {
  photos: PropTypes.array,
};

export default ThreeAsymmetricalPortraits;
