import React from 'react';
import PropTypes from 'prop-types';
// import { scroller } from 'react-scroll';

import ArrowDown from '../../../../svg/arrow-down-right.svg';

const BottomLine = ({ socials }) => {
  // function scrollDown() {
  //   scroller.scrollTo('second-section', {
  //     duration: 500,
  //     delay: 0,
  //     smooth: 'easeInOutQuart',
  //   });
  // }

  // function scrollTo() {
  //   scroller.scrollTo('person', {
  //     duration: 500,
  //     delay: 0,
  //     smooth: 'easeInOutQuart',
  //   });
  // }

  return (
    <div className="main-screen__bottom">
      <a href="#second-section" className="scroll-down" data-scroll-to>
        <span className="scroll-down__title">Scroll Down</span>
        <span className="scroll-down__icon">
          <ArrowDown />
        </span>
      </a>
      <a href="#biography" className="scroll-down scroll-down__person" data-scroll-to>
        <span className="scroll-down__icon">
          <ArrowDown />
        </span>
        <span className="scroll-down__title">About Photographer</span>
      </a>
    </div>
  );
};

BottomLine.propTypes = {
  socials: PropTypes.array,
};

export default BottomLine;
