export const photographerInnerData = {
  main: {
    title: 'Claudia Biondini',
    video_cover: '../../../../../video/home-real-2.mp4',
    showreel: 'https://vimeo.com/274546224',
    description: 'Photographer',
  },
  about: {
    title: 'Claudia Biondini',
  },
  portfolio: [
    { type: 'TwoPortraits' },
    { type: 'ThreePortraitsBottom' },
    { type: 'ThreePortraitsTop' },
    { type: 'ThreeAsymmetricalPortraits' },
    { type: 'FourPortraits' },
    { type: 'LandscapePortrait' },
    { type: 'PortraitLandscape' },
    { type: 'TwoLandscapes' },
    { type: 'OneLandscape' },
  ],
};
